.manage-movies-wrapper {
    margin: 2%;
}

.manage-movies-wrapper > .ant-select {
    width: 25%;
}

.manage-movies-wrapper > .ant-table-wrapper {
    margin-top: 2%;
}
