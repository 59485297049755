.add-content-wrapper {
    margin: 2%;
}

.add-content-tabs {
    background-color: #fff;
    padding: 2%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.content-type-option {
    display: block;
    margin: 2% auto;
}

.form-card {
    margin: 2% auto;
}

.ant-card-head {
    background-color: #f0f2f5;
}

.form-item-selector {
    display: flex;
    justify-content: space-between;
}

.form-item-selector > .ant-form-item-control {
    max-width: 75%;
}

.add-btn {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
    width: 25%;
    float: center;
    margin-bottom: 3%;
}

.add-btn:hover {
    color: #fff;
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    cursor: pointer;
}

.continue-btn {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
    width: 25%;
    float: right;
    margin-bottom: 3%;
}

.continue-btn:hover {
    color: #fff;
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    cursor: pointer;
}

.next-btn {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
    width: 25%;
    float: right;
}

.next-btn:hover {
    color: #fff;
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    cursor: pointer;
}

.finish-btn {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
    width: 25%;
    float: right;
}

.finish-btn:hover {
    color: #fff;
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    cursor: pointer;
}
