.manage-episodes-wrapper {
    margin: 2%;
}

.manage-episodes-wrapper > .ant-select {
    width: 25%;
}

.manage-episodes-wrapper > .ant-table-wrapper {
    margin-top: 2%;
}
