.manage-media-house-wrapper {
    margin: 2%;
}

.ant-input-number {
    width: 100%;
}

.add-media-house-btn {
    /* position: absolute;
    right: 24px;
    bottom: 24px; */

    position: relative;
    margin-left: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    float: right;
}

.add-media-house-btn:hover {
    cursor: pointer;
}