.ant-layout-header {
    background-color: rgb(1, 29, 17);
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-logo {
    height: 40px;
}

/*
.logout-btn {
    background-color: #666;
    color: #ccc !important;
}*/

.logout-btn:hover {
    background-color: var(--primary-hover) !important;
    color: white !important;
}