.manage-series-wrapper {
    margin: 2%;
}

.add-series-btn {
   /*  position: absolute;
    right: 24px;
    bottom: 24px; */
}

.add-series-btn:hover {
    cursor: pointer;
}

.export-btn {
    /* position: absolute;
    right: 96px;
    bottom: 24px; */
    font-size: 48px;
    color: #1890ff;
}

.export-btn-only {
    /*  position: absolute;
    right: 36px;
    bottom: 24px; */
    font-size: 48px;
    color: #1890ff;
}


.export-btn-only,
.export-btn,
.add-series-btn {
    position: relative;
    margin-left: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    float: right;
}