.add-content-btn {
    display: block;
    width: 75%;
    margin: 5% auto;
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}

.add-content-btn:hover {
    color: #fff;
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #FCE4EC;
}

.ant-menu-submenu-selected,
.ant-menu-item-selected {
    color: var(--primary);
}

.ant-menu-inline .ant-menu-item::after {
    border-color: var(--primary);
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
    color: var(--primary-hover);
}